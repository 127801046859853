const regexAElements = /<a (.+?)>/gim;
const regexIsFYM = /href="(http|https):\/\/([a-z0-9]+[.])*(freeyourmusic\.com|musicapi\.com|songsbrew\.com).*?"/im;

export function fixBlogLinks(content: string) {
  let newContent = content;
  const matches = Array.from(content.matchAll(regexAElements));
  matches.forEach((match) => {
    const matchString = match[0];
    const isFYM = matchString.match(regexIsFYM) !== null;
    if (!isFYM && !matchString.includes('target="')) {
      newContent = newContent.replace(matchString, matchString.replace('<a ', '<a target="blank" '));
    }
  });
  return newContent;
}
