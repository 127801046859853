import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from 'reactstrap';

export const BlogCTAContent: React.FC<{
  locale: string;
}> = React.memo(({ locale }) => {
  const intl = useIntl();
  return (
    <div
      role="button"
      onKeyDown={window.onBlogCTAClicked}
      aria-hidden
      onClick={window.onBlogCTAClicked}
      className="blog-cta-inner p-2"
    >
      <h3 className="m-0 py-1">
        <FormattedMessage
          id="web.move.ratings.title"
          defaultMessage="Join millions moving their playlists with FreeYourMusic"
        />
      </h3>
      <div className="d-flex flex-row align-items-center justify-content-center py-1">
        <Button
          color="primary"
          className="px-5"
          href={`/${locale}/download`}
          onClick={(e) => {
            e.preventDefault();
            window.onBlogCTAClicked();
          }}
        >
          {intl.formatMessage({
            defaultMessage: 'Transfer my music now!',
            id: 'web.blog.cta.transfer_my_music'
          })}
        </Button>
      </div>
    </div>
  );
});
