import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { TypedMemo } from './types';
import { FymButton } from './FymButton';
import { appleMusicAffiliateToken } from './consts';

const appleMusicPromoURL = `https://music.apple.com/subscribe?itsct=music_box_badge&itscg=30200&at=${appleMusicAffiliateToken}&ct=blog&app=music&ls=1%22`;
const AppleMusicAdComponent: React.FC<React.PropsWithChildren<unknown>> = () => {
  const intl = useIntl();
  const onAdClick = useCallback(() => {
    window.open(appleMusicPromoURL, '_blank', 'noopener noreferrer');
  }, []);
  return (
    <div
      className="d-flex justify-content-center align-items-center flex-column mx-auto"
      style={{ width: '50%' }}
      onClick={onAdClick}
      onKeyDown={onAdClick}
      role="button"
      aria-hidden
    >
      <div className="flex-1 d-flex justify-content-center apple-ad-a">
        <div className="apple-ad-container">
          <h3 className="ui-text">
            {intl.formatMessage({
              id: 'app.apple-music.get-free-apple-music',
              defaultMessage: 'Get 1 month of Apple Music for FREE'
            })}
          </h3>
          <FymButton href={appleMusicPromoURL}>
            {intl.formatMessage({
              id: 'app.apple-music.try-it-now',
              defaultMessage: 'Try it now!'
            })}
          </FymButton>
          <h2 className="ui-text">
            {intl.formatMessage(
              {
                id: 'app.apple-music.best-music',
                defaultMessage: 'Our <b>BEST MUSIC</b> service'
              },
              {
                b: (str: React.ReactNode[]) => <strong>{str}</strong>
              }
            )}
          </h2>
        </div>
      </div>
    </div>
  );
};

export const AppleMusicAd = TypedMemo(AppleMusicAdComponent);
